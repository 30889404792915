<!-- Settings -->
<template>
<div>
  <!-- <el-row >
    <el-col :span="24" style="text-align:center;">
      <span class="text_header">Welcome to Tevola Management</span>
    </el-col>
  </el-row> -->
  <el-row class="mt-5">
    <el-col :span="5"  :gutter="12" :offset="1" >
      <router-link  to="/employee/list"> 
        <el-card :body-style="{ padding: '0px' }" style="border-radius:15px;">
          <div class="dashb-card-images">
            <div class="thumbnail-box-svg"><i class="menu-icon flaticon-users-1"></i></div>
          </div>
          <div style="padding: 14px; height:75px;">
            <div style="text-align:center"> <h4 style="font-weight:700; color:#2e3192">Employees</h4></div>          
          </div>
        </el-card>
      </router-link>
    </el-col>    
    <el-col :span="5"  :gutter="12" :offset="1" >
      <router-link  to="/employee/create"> 
        <el-card :body-style="{ padding: '0px' }" style="border-radius:15px;">
          <div class="dashb-card-images">
            <div class="thumbnail-box-svg"><i class="menu-icon flaticon2-user"></i></div>
          </div>
          <div style="padding: 14px; height:75px;">
            <div style="text-align:center"><h4 style="font-weight:700; color:#2e3192">Add Employee</h4></div>          
          </div>
        </el-card>
      </router-link>
    </el-col>    
    <el-col :span="5"  :gutter="12" :offset="1" >
      <router-link  to="/employee/time/sheet"> 
        <el-card :body-style="{ padding: '0px' }" style="border-radius:15px;">
          <div class="dashb-card-images">
            <div class="thumbnail-box-svg"><i class="menu-icon flaticon-calendar-with-a-clock-time-tools"></i></div>
          </div>
          <div style="padding: 14px; height:75px;">
            <div style="text-align:center">  <h4 style="font-weight:700; color:#2e3192">Employee Time Sheet</h4></div>          
          </div>
        </el-card>
      </router-link>
    </el-col>  
</el-row>
</div>
</template>
<style>
.bg-dark {    background-color: #ffffff !important; text-align: center !important;}
.imglogo img{width: 90%; height: 50px;}
.text_header{
  font-size: 35px;
  font-weight: 600;
  font-family: 'Flaticon';
}
.dashb-card-images{
  position: relative;
    width: 100%;
    height: 150px;
    background: url(/media/images/office-pattern.jpg) 50%;
    background-size: 500px;
}
.thumbnail-box-svg{
   font-size: 20px;
    position: sticky;
    margin: auto;
    left: 0px;
    right: 0;
    top: 150px;
    width: 60px;
    height: 60px;
    color: #2e3192;
    line-height: 60px;
    border-radius: 50%;
    background: #fff;
    text-align: center;
    box-shadow: 0 10px 15px rgb(234 235 249);
}
</style>
<script>
export default {
  name: "dashboard",
   data() {
      return {
         loading: false,
      }
  }, 
};
</script>

